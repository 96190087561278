

















import Vue from "vue";
export default Vue.extend({
  name: "Pagination",
  props: [
    "defaultCurrent",
    "total",
    "pageSizeSet",
    "disabled",
    "idPagination",
    "pageSizeOptions",
  ],
  data() {
    return {
      pagepagination: {},
      currentPage: 1,
    };
  },
  methods: {
    onShowSizeChange(current: any, pageSize: any): void {
      this.currentPage = current;
      this.pagepagination = {
        page: current,
        size: pageSize,
        idPagination: this.idPagination,
      };
      this.$emit("response-pagesize-change", this.pagepagination);
    },
    change(current: any, pageSize: any): void {
      this.currentPage = current;
      this.pagepagination = {
        page: current,
        size: pageSize,
        idPagination: this.idPagination,
      };
      this.$emit("response-currentpage-change", this.pagepagination);
    },
  },
});
